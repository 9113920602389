<template>
  <div>
    <LandingPosterSection/>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    LandingPosterSection: () => import(/* webpackChunkName: "LandingPosterSection" */'../components/LandingPosterSection')
  }
}
</script>
